<script setup lang="ts">
import { useAnalytics, type AnalyticsConfig } from "./composables/useAnalytics";

useState("use-black-logo", () => false);

const { appName } = useEnvPaths();

const { isConsent, sendScreenView } = useAnalytics();
const consent = useCookie(`${appName()}_cookieConsent`);

onMounted(async () => {
  const { checkGdprLocale } = useGdprStore();
  checkGdprLocale();

  const { getAnalyticsUrl } = useEnvPaths();

  const analyticsConfig: AnalyticsConfig = {
    zenAnalyticsUrl: getAnalyticsUrl() as string,
    appName: appName() as string,
    useZenAnalytics: true,
    useGtag: useRuntimeConfig().public.nodeEnv === "production",
  };

  useAnalytics(analyticsConfig);

  isConsent.value = !!consent.value;

  const route = useRoute();

  sendScreenView({
    action: "FirstWebStart",
    category: route.name as string,
  });
});

function onCookieConsentAccepted(): void {
  isConsent.value = true;
  consent.value = "true";
  sendScreenView({ action: "GDPR", category: "ConsentAllowed" });
}
</script>

<template>
  <div class="app-main">
    <TheHeader />

    <main>
      <NuxtPage />
    </main>

    <TheFooter />

    <LazyConsentFooter
      v-if="!isConsent"
      role="dialog"
      @accepted="onCookieConsentAccepted"
    />
  </div>
</template>
