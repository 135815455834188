<script setup lang="ts">
type ModalProps = {
  backgroundColor?: string;
  backdropOnly?: boolean;
};

const props = withDefaults(defineProps<ModalProps>(), {
  backgroundColor: "black",
  backdropOnly: false,
});

const emit = defineEmits(["close-modal"]);

const { getCdnUrl } = useEnvPaths();
</script>

<template>
  <div class="modal-backdrop" :class="props.backgroundColor">
    <template v-if="!props.backdropOnly">
      <div class="modal">
        <div class="close-modal-button">
          <button aria-label="Close panel" @click="emit('close-modal')">
            <img
              :src="`${getCdnUrl()}/assets/icons/close-black.svg`"
              alt="Close"
            />
          </button>
        </div>
        <div v-if="!!$slots.footer" class="modal-header">
          <h1>
            <slot name="title" />
          </h1>
        </div>
        <section class="modal-body">
          <slot name="content" />
        </section>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="backdrop-only">
        <button aria-label="Close panel" @click="emit('close-modal')">
          <img
            :src="`${getCdnUrl()}/assets/icons/close-black.svg`"
            alt="Close"
          />
        </button>
        <slot />
      </div>
    </template>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/_vars";
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  &.black {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.white {
    background-color: rgb(255, 255, 255);
  }

  .modal {
    background: #ffffff;
    box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.3);
    overflow-x: auto;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    border-radius: 30px;
    max-width: $max-view-width;
    max-height: 96vh;
    padding: 1rem;
    margin: 2rem;
    overflow-y: auto;
    position: relative;

    @media (max-width: $device-xs) {
      padding-bottom: 0 !important;
    }

    .close-modal-button {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      button {
        cursor: pointer;
        position: absolute;
        top: 3rem;
        right: 20px;
        z-index: 1;

        @media (max-width: $device-xs) {
          right: 0;
          top: 2.25rem;
        }

        img {
          width: 48px;
          height: 48px;

          @media (max-width: $device-xs) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    @media (max-width: $device-xs) {
      padding: 3rem 2rem;
      margin: 1rem !important; //1rem;
    }

    .modal-header {
      display: absolute;
      position: relative;
      padding-top: 1rem;

      @media (max-width: $device-xs) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      h1 {
        margin: 0;
        text-align: center;
        font-size: 4.5rem;

        @media (max-width: $device-xs) {
          text-align: left;
          font-size: 3.7rem;
          letter-spacing: 0.15px;
        }
      }
    }

    .modal-body {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
    }
  }

  .backdrop-only {
    width: auto;
    margin: auto;
    width: 100%;
    max-width: 1400px;
    display: flex;
    gap: 2rem;
    flex-direction: column;

    button {
      margin-left: auto;
      cursor: pointer;
    }
  }
}
</style>
