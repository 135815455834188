<script setup lang="ts">
import SubmitAnimation from "./SubmitAnimation.vue";

const props = defineProps({
  success: {
    type: Boolean,
  },
});
</script>

<template>
  <div
    class="form-success"
    role="alertdialog"
    aria-labelledby="submittingTitle"
    aria-describedby="submittingMessage"
  >
    <h2 v-show="props.success" id="submittingTitle">
      <slot name="title" />
    </h2>
    <h2 v-show="!props.success">Submitting ...</h2>
    <div class="success-icon">
      <SubmitAnimation :is-animating="!props.success" />
    </div>
    <p v-show="props.success" id="submittingMessage">
      <slot name="message" />
    </p>
    <p v-show="!props.success"></p>
  </div>
</template>

<style lang="scss">
@import "../../assets/scss/_vars";
.form-success {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  align-items: center;
  text-align: center;
  h2 {
    font-size: 4.5rem;
    margin-bottom: 0;

    @media (max-width: $device-lg) {
      max-width: 800px;
    }

    @media (max-width: $device-xs) {
      font-size: 3rem;
    }
  }
  p {
    color: black !important;
    font-size: 3rem !important;
  }
}
</style>
