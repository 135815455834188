import type { FacebookLoginResponse } from "@/types/";

export const useFacebookLogin = () => {
	const facebookSdkReady = ref(false);

	const facebookLoginInit = async () => {
		const resp = await installFacebookSdkScript(document, "script", "facebook-jssdk");
		return resp;
	}

	const installFacebookSdkScript = async (d: Document, s, id: string) => {
		return new Promise(async (resolve) => {
			if (d.getElementById(id)) {
				facebookSdkReady.value = true;
				resolve(facebookSdkReady.value);
			}

			const fjs = d.getElementsByTagName(s)[0];
			const js = d.createElement(s);
			js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);

			const { data: appId } = await useFetch("/api/auth/facebook-client-id");

			window.fbAsyncInit = () => {
				FB.init({
					appId: appId.value as string,
					cookie: true,
					xfbml: true,
					version: "v3.2",
				});

				FB.AppEvents.logPageView();
				facebookSdkReady.value = true;
				resolve(facebookSdkReady.value);
			};
		});
  }

	const facebookLoginStatus = async () => {
		return new Promise(resolve => {
			FB.getLoginStatus(function (resp) {
				resolve(resp.status);
			});
		})
	}

	const facebookLogin = async (): Promise<FacebookLoginResponse> => {
		return new Promise(resolve => {
			FB.login(function (resp) {
				resolve(resp as unknown as FacebookLoginResponse);
			});
		})
	}

	const facebookLogout = async () => {
		return new Promise(resolve => {
			FB.logout(function (resp) {
				resolve(resp);
			});
		})
	}

	return {
		facebookSdkReady,
		facebookLoginInit,
		facebookLoginStatus,
		facebookLogin,
		facebookLogout,
	}
}