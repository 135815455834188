import { default as _91_46_46_46slug_93HJmYAfZ7GLMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/[...slug].vue?macro=true";
import { default as _91id_93e4hPmkzhdRMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/article/[id].vue?macro=true";
import { default as features_45under_45developmentyWWAhgUrTtMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/features-under-development.vue?macro=true";
import { default as indexDUFsZDPxDLMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/index.vue?macro=true";
import { default as loginbzrfDvmqVKMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/login.vue?macro=true";
import { default as weddingsJh0Ns3AmyvMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/weddings.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "article-id",
    path: "/article/:id()",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/article/[id].vue").then(m => m.default || m)
  },
  {
    name: "features-under-development",
    path: "/features-under-development",
    meta: features_45under_45developmentyWWAhgUrTtMeta || {},
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/features-under-development.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginbzrfDvmqVKMeta || {},
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "weddings",
    path: "/weddings",
    meta: weddingsJh0Ns3AmyvMeta || {},
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/weddings.vue").then(m => m.default || m)
  }
]