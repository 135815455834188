import type { AnalyticsObject } from '@/types';
import { useState } from 'vue-gtag-next'; //useGtag

export type AnalyticsConfig = {
	zenAnalyticsUrl: string,
	gtagId?: string,
	appName: string,
	useZenAnalytics: boolean,
	useGtag: boolean,
	heartbeat?: boolean,
	heartbeatInterval?: number,
}

const useConfig = ref<AnalyticsConfig>({
	zenAnalyticsUrl: '',
	gtagId: '',
	appName: '',
	useZenAnalytics: false,
	useGtag: false,
	heartbeat: false,
	heartbeatInterval: 60000,
});

const isConsent = ref(false);

watch(isConsent, () => {
	if (useConfig.value.useGtag && isConsent.value) {
		const { isEnabled } = useState();
		isEnabled!.value = useConfig.value.useGtag;
	}
});

const sendScreenView = (analyticsObject: AnalyticsObject, ignoreGtag = false): void => {
	if (isConsent.value) {
		console.log('useRuntimeConfig().public.nodeEnv: ', useRuntimeConfig().public.nodeEnv);
		if (!ignoreGtag && useConfig.value.useGtag && useRuntimeConfig().public.nodeEnv === 'production') {
			console.log('DO NOT SEND SCREEN VIEW!!!!!!!');
			// const { screenview } = useGtag();
			// screenview({
			// 	app_name: useConfig.value.appName,
			// 	screen_name: analyticsObject.category,
			// });
		}

		if (useConfig.value.useZenAnalytics) {
			sendZenAnalytics(analyticsObject);
		}
	}
}

const sendZenAnalytics = (analyticsObject: AnalyticsObject): void => {
	const { getGuid } = useUtils();
	const baseUrl = useConfig.value.zenAnalyticsUrl;
	const params = `action=${analyticsObject.action}.${analyticsObject.category}&app=website`;
	const query = `?id=${getGuid()}&${params}`;
	const path = `${baseUrl}${query}`;
	

	$fetch(path)
}

export function useAnalytics(config: AnalyticsConfig | null = null) {
	if (config) {
		useConfig.value = config;
	}

	return {
		isConsent,
		useConfig,
		sendScreenView,
	}
}