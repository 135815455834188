<template>
  <div class="buy-tickets">
    <div class="ticket-grid">
      <div class="ticket-item cta-hover-orange">
        <h2>Times Travel<br />Museum</h2>
        <p class="description contrasted-color">
          includes access to Times Travel Museum<br />and Observatory
        </p>
        <p class="price">$35</p>
      </div>
      <div class="ticket-item cta-hover-blue">
        <h2>NextGen Brand<br />Experience</h2>
        <p class="description contrasted-color">
          includes access to NextGen Experience
        </p>
        <p class="price">$25</p>
      </div>
      <div class="ticket-item cta-dark-gray cta-hover-green">
        <h2>Combination</h2>
        <p class="description contrasted-color">
          includes access to NextGen Experience<br />Times Travel Museum and
          Observatory
        </p>
        <p class="price">$50</p>
      </div>
      <div class="ticket-item cta-light-gray cta-hover-yellow">
        <h2>VIP Access</h2>
        <p class="description contrasted-color">
          includes access to NextGen Experience<br />Times Travel Museum and
          Observatory
        </p>
        <p class="price">$75</p>
      </div>
    </div>
    <div class="ticket-aside">
      <div class="ticket-item cta-dark-gray cta-hover-red">
        <h2>Promo and Special Events</h2>
        <p class="description contrasted-color" style="padding-top: 10rem">
          See our current and upcoming promotions and special events!
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.buy-tickets {
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 1400px;
  padding: 0 2rem;

  .ticket-grid {
    flex: 1;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    gap: 1rem;
  }

  .ticket-item {
    padding: 3.75rem 4rem;
    background-color: white;
    border-radius: var(--border-radius-lg);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    cursor: pointer;
    transition: all 0.5s;

    * {
      transition: all 0.5s;
    }

    h2 {
      margin: 0;
      padding: 0;
      line-height: 1.1;
      font-weight: 700;
      font-size: 4rem;
    }

    p {
      margin: 0;
      font-weight: 700;
      line-height: 1.3;

      &.description {
        color: rgb(130, 130, 130);
      }

      &.price {
        margin-top: auto;

        font-size: 4.5rem;
        font-weight: 900;
      }
    }
  }

  .ticket-aside {
    width: 300px;
    .ticket-item {
      width: 100%;
    }
  }
}
</style>
