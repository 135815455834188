import { defineStore } from 'pinia';
import type { SocialLoginResponse, AuthToken, SocialSigninRegistrationResp, UserProfile, LoginObject } from "@/types/";

export const useAuthStore = defineStore('authStore', () => {
	const { getZenApiUrl } = useEnvPaths();
	const { appName } = useEnvPaths();
	const headers = {
		"Content-Type": "application/json",
	};

	const userProfile = ref<UserProfile>();
	const token = useCookie(`${appName()}_accessToken`);
	const accessToken = ref(token.value);
	const authenticated = ref(!!token.value);
	const { getGuid } = useUtils();
	const config = useRuntimeConfig();

	const signinWithCredentials = async (email: string, password: string) => {
		try {
			const loginInfo: LoginObject = {
				email: email,
				password: password,
				device: {
					deviceId: getGuid(),
					type: "browser",
				},
				tenant: config.public.tenant as string,
			}

			//const headers = useRequestHeaders(['cookie']);
			const resp: AuthToken = await $fetch('/api/auth/login-credentials', { 
				headers: {
					'Content-Type': 'application/json',
				},
				body: loginInfo,
				method: 'POST' 
			});

			if (resp.error) {
				return {
					error: resp,
				}
			} else {
				const token = useCookie(`${appName()}_accessToken`, {
					expires: new Date(resp.expiresAt),
				});
				
				token.value = resp.accessToken;
				accessToken.value = resp.accessToken;
				authenticated.value = true;
				return { token: token.value };
			}
			
		} catch (e: any) {
			console.error('signinWithCredentials error', e);
			return { error: e};
		}
	}

	const checkSocialSigninRegistration = async (evt: SocialLoginResponse): Promise<SocialSigninRegistrationResp> => {
		const { getGuid } = useUtils();

		try {
			const resp: AuthToken = await $fetch(
				`${getZenApiUrl()}/auth/social-login/${evt.type}`,
				{
					headers,
					body: {
						socialToken: evt.access_token,
						tenant: config.public.tenant as string,
						device: {
							deviceId: getGuid(),
							type: "browser",
						},
					},
					method: "POST",
				}
			);

			const token = useCookie(`${appName()}_accessToken`, {
				expires: new Date(resp.expiresAt),
			});
			
			token.value = resp.accessToken;
			accessToken.value = resp.accessToken;
			authenticated.value = true;

			if (evt.type === 'facebook') {
				const { facebookLogout } = useFacebookLogin();
				facebookLogout();
			} else if (evt.type === 'google') {
				const { googleLogout } = useGoogleLogin();
				googleLogout();
			}

			return { token: token.value };
		} catch(e: any) {
			console.error('checkSocialSigninRegistration error', e.status);
			return { error: {
				status: e.status,
				access_token: evt.access_token,
			}};
		}
	}

	const socialRegistration = async (token: SocialLoginResponse) => {
		try {
			await $fetch(`${getZenApiUrl()}/auth/social-signup/${token.type}`, {
				headers: {
					"Content-Type": "application/json",
				},
				body: {
					socialToken: token.access_token,
					tenant: config.public.tenant as string,
				},
				method: "POST",
			});

			const validatedResp = await checkSocialSigninRegistration(token);
			return validatedResp;
		} catch (e: any) {
			console.error("registerOauthUser error :", e);
			return { error: {
				status: e.status,
				access_token: token.access_token,
			}};
		}
	}

	const isAuthenticated = () => {
		return authenticated.value = !!token.value || !!accessToken.value;
	}

	const getUserProfile = async() => {
		if (!userProfile.value) {
			const header: HeadersInit = {
				Authorization: `Bearer ${accessToken.value}`,
			};

			const data = await $fetch(`${getZenApiUrl()}/profile`, {
				headers: header,
				method: "GET",
			});

			userProfile.value = data as UserProfile;
		}
		return userProfile.value;
	}

	const signOut = () => {
		authenticated.value = false;
		token.value = null;
		userProfile.value = undefined;
	}

	return {
		signinWithCredentials,
		checkSocialSigninRegistration,
		socialRegistration,
		isAuthenticated,
		authenticated,
		getUserProfile,
		signOut,
	}
});