<script setup lang="ts">
import FormSubmitting from "./FormSubmitting.vue";
import type { SignupData } from "@/types";

const { sendScreenView } = useAnalytics();

// Means this is opening in modal to send unique PageView
if (window.location.pathname !== "/") {
  sendScreenView(
    { action: "PageView", category: "stay-in-the-loop-form" },
    true
  );
}

const emit = defineEmits(["close"]);

const signupFormData = ref<SignupData>({
  firstname: "",
  options: [],
  email: "",
  consent: true,
});

const validationError = ref(false);
const signupSuccess = ref(false);
// const signupError = ref<string | null>(null);
const isSubmitting = ref(false);

const isValidTextInput = computed(() => {
  return (input: string) => input.length > 0;
});

const isValidEmail = computed(() => {
  const { isEmail } = useUtils();
  return (email: string) => isEmail(email);
});

function onSelectOption(evt: MouseEvent): void {
  const checkbox: HTMLInputElement = evt.target as HTMLInputElement;
  if (checkbox.checked) {
    signupFormData.value.options.push(checkbox.value);
  } else {
    signupFormData.value.options = signupFormData.value.options.filter(
      (value) => value !== checkbox.value
    );
  }
}

async function onSubmitHandler(evt: MouseEvent) {
  evt.preventDefault();

  if (
    !isValidTextInput.value(signupFormData.value.firstname) ||
    !isValidEmail.value(signupFormData.value.email)
  ) {
    validationError.value = true;
    return;
  }

  isSubmitting.value = true;

  const { getZenApiUrl } = useEnvPaths();

  try {
    await $fetch(getZenApiUrl() + "/zen/zen-bridge-emailSubscription", {
      method: "POST",
      body: signupFormData.value,
      headers: { "content-type": "application/json" },
    });
    signupSuccessHandler();
  } catch (error) {
    // console.log(error);
    // throw error here
  }

  isSubmitting.value = false;
}

function signupSuccessHandler(): void {
  signupSuccess.value = true;

  sendScreenView({
    action: "SignupWeb",
    category: "OTSsignup",
  });
}

function onFormReset() {
  signupSuccess.value = false;
  signupFormData.value = {
    firstname: "",
    options: [],
    email: "",
    consent: true,
  };

  emit("close");
}
</script>

<template>
  <div class="signup-form">
    <div v-if="!signupSuccess && !isSubmitting" class="sign-up-component">
      <h2>Be the first to know.</h2>
      <h3>
        Sign up for special invitations, ticket releases, and other
        announcements.
      </h3>
      <form>
        <div class="form-row">
          <div
            class="form-item"
            :class="{
              invalid:
                !isValidTextInput(signupFormData.firstname) && validationError,
            }"
          >
            <input
              v-model="signupFormData.firstname"
              name="name"
              type="text"
              autocomplete="name"
              required
              aria-required="true"
              aria-label="Enter your name"
              placeholder="First Name"
            />
            <div class="form-error-message">Your name is required</div>
          </div>
        </div>

        <div class="form-row">
          <div
            class="form-item"
            :class="{
              invalid: !isValidEmail(signupFormData.email) && validationError,
            }"
          >
            <input
              v-model="signupFormData.email"
              name="email"
              type="email"
              autocomplete="email"
              required
              aria-required="true"
              aria-label="Enter your email"
              placeholder="Email"
            />
            <div class="form-error-message">Email is required</div>
          </div>
        </div>

        <fieldset class="know-more">
          <legend>I would like to learn more about</legend>
          <div class="checkboxes">
            <div class="checkbox">
              <input
                id="nye"
                type="checkbox"
                name="nye"
                value="nye"
                @click="onSelectOption"
              />
              <label for="nye">New Year's Eve</label>
            </div>
            <div class="checkbox">
              <input
                id="openingEvent"
                type="checkbox"
                name="openingEvent"
                value="openingEvent"
                @click="onSelectOption"
              />
              <label for="openingEvent">Opening of One Times Square</label>
            </div>
            <div class="checkbox">
              <input
                id="wedding"
                type="checkbox"
                name="wedding"
                value="wedding"
                @click="onSelectOption"
              />
              <label for="wedding"
                >Hosting a private event at One Times Square</label
              >
            </div>
            <div class="checkbox">
              <input
                id="all"
                type="checkbox"
                name="all"
                value="all"
                @click="onSelectOption"
              />
              <label for="all">All of the above</label>
            </div>
          </div>
        </fieldset>

        <div class="terms">
          By submitting, you grant us permission to email you updates in
          accordance with our
          <a
            href="https://www.jamestownlp.com/privacy-policy-and-disclaimers"
            target="_blank"
            aria-label="Read privacy policy"
            >Privacy Policy</a
          >.
        </div>

        <div class="form-row signup-button">
          <button
            type="submit"
            class="primary-button"
            :disabled="isSubmitting"
            aria-label="Submit signup"
            @click="onSubmitHandler"
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>

    <FormSubmitting
      v-if="isSubmitting || signupSuccess"
      :success="signupSuccess"
    >
      <template #title> Your request has been successfully sent! </template>
      <template #message> We'll be in touch! </template>
    </FormSubmitting>

    <div v-if="signupSuccess" class="back-link">
      <nuxt-link
        aria-label="You have successfully signed up. Close form."
        @click="onFormReset"
        >Close</nuxt-link
      >
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/_vars";
.signup-form {
  width: 800px;
  max-width: 800px;
  min-height: 575px;
  margin: auto;

  @media (max-width: $device-sm) {
    width: 100%;
    padding-top: 1rem;
  }

  @media (max-width: $device-xs) {
    min-height: auto;
    padding: 0;
  }

  .back-link {
    color: black;
    font-size: var(--font-size-p);
    cursor: pointer;
    text-align: center;

    a {
      color: black;
    }
  }
}

.sign-up-component {
  padding: 3rem;
  border-radius: 25px;
  background: white;
  backdrop-filter: blur(1px);
  display: inline-grid;
  grid-gap: 2rem;
  max-width: 800px;

  @media (max-width: $device-xs) {
    padding: 0;
    grid-gap: 1rem;
  }

  h2 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 4.5rem;
    line-height: 1;
    letter-spacing: -0.7px;
    color: black;

    @media (max-width: $device-xs) {
      font-size: 3rem;
    }

    span {
      white-space: nowrap;
    }
  }

  h3 {
    font-size: var(--font-size-h3);
    font-weight: 700;
    margin-top: 0;

    @media (max-width: $device-xs) {
      margin: 0;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    .form-row {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      width: 100%;
      position: relative;

      @media (max-width: $device-xs) {
        flex-direction: column;
      }

      .form-item {
        width: 100%;
        position: relative;

        input {
          font-family: "Poppins", "sans-serif";
          background: white;
          padding: 1.6rem 2rem;
          width: 100%;
          border-radius: 6px;
          font-size: 1.6rem;
          color: #626262;
          transition:
            border,
            box-shadow 500ms ease-in-out;
          border: 1px solid black;

          @media screen and (max-width: $device-xs) {
            font-size: 2.25rem;
            padding: 1rem 2rem;
          }

          &:focus {
            outline: none;
            border-width: 2px;
          }

          &:autofill {
            -webkit-text-fill-color: black !important;
            border: 1px solid black;
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: 0 0 0 1000px #ffffff inset !important;
          }

          &::placeholder {
            color: black;
          }
        }

        .form-error-message {
          display: none;
        }

        &.invalid {
          input {
            border-color: red !important;
            animation: shake 0.6s both;
          }

          .validator-icon {
            width: 48px;
            height: 48px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            background: url(/assets/icons/close-black.svg) no-repeat;
          }

          .form-error-message {
            color: red;
            padding: 0.5rem;
            display: block;
          }
        }
      }
    }

    .signup-button {
      text-align: center;
      .primary-button {
        padding-left: 8rem;
        padding-right: 8rem;
        margin: auto;
      }
    }

    .know-more {
      width: 100%;
      border-width: 0;

      legend {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 1rem;

        @media screen and (max-width: $device-xs) {
          font-size: 2.25rem;
        }
      }
    }

    .terms {
      padding: 2rem 0;
      text-align: left;
      width: 100%;
      font-size: 1.1rem;

      @media screen and (max-width: $device-xs) {
        font-size: 1.8rem;
        padding: 0;
      }

      a {
        color: black;
      }
    }

    label {
      font-size: 1.6rem;

      @media screen and (max-width: $device-xs) {
        font-size: 2rem;
      }

      &.terms {
        font-size: 1.3rem;
      }
    }

    .checkboxes {
      display: grid;
      grid-template-columns: auto auto;
      width: 100%;
      gap: 1rem;
    }

    .checkbox {
      display: inline-grid;
      grid-template-columns: auto 1fr;
      gap: 1.5rem;
      align-items: center;

      input[type="checkbox"] {
        width: 30px;
        height: 30px;
        accent-color: darkgray;
        border: 3px solid #ddd;
        border-radius: 10px !important;
      }
    }
  }

  .agree-block {
    color: white;

    p {
      font-size: 1rem;
      text-align: justify;

      @media screen and (max-width: $device-xs) {
        font-size: 2rem;
      }
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}
</style>
