import { useEnvPaths } from '@/composables/useEnvPaths';

export const useUtils = () => {
	const getGuid = (): string => {
		const { appName } = useEnvPaths();
		const guidCookie = useCookie(`${appName()}_guid`);
		if (import.meta.client) {
			if (guidCookie.value) {
				return guidCookie.value;
			}

			let d = new Date().getTime() + performance.now();

			const newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
				const r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
			});

			guidCookie.value = newGuid;

			return guidCookie.value;
		} else {
			return 'none';
		}
	}

	const getInitials = (value: string): string => {
		return value.match(/(\b\S)?/g).join("").toUpperCase();
	}

	const getYear = (): number => {
		return new Date().getFullYear();
	}

	const isEmail = (value: string): boolean => {
		const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
		return value.match(mailformat) ? true : false;
	};

	const isMobileDevice = () => {
		return isAndroid() || isIos() || isIpadOs() ? true : false;
	}

	function isAndroid() {
		return /Android/i.test(navigator.userAgent);
	}

	function isIos() {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent);
	} 

	function isIpadOs() {
		return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.userAgent);
	}

	return {
		getGuid,
		getInitials,
		getYear,
		isEmail,
		isMobileDevice,
	}
};