<script setup lang="ts">
import { storeToRefs } from "pinia";
import SignUp from "./forms/SignUp.vue";

const { sendScreenView } = useAnalytics();

const { authenticated } = storeToRefs(useAuthStore());
const { isGdprRestricted } = storeToRefs(useGdprStore());

const { getCdnUrl } = useEnvPaths();

const route = useRoute();

const openForm = ref(false);

const windowScrollY = ref();

const useBlackLogo = useState("use-black-logo");

const openTicketsModal = ref(false);

const getLogo = computed(() => {
  if (useBlackLogo.value) {
    return `${getCdnUrl()}/assets/logos/ots-logo-black.svg`;
  }
  const logo = whiteHeader.value ? "ots-logo-black.svg" : "ots-logo.svg";
  return `${getCdnUrl()}/assets/logos/${logo}`;
});

const getLogoMobile = computed(() => {
  if (useBlackLogo.value) {
    return `${getCdnUrl()}/assets/logos/ots-logo-mobile-black.svg`;
  }
  const logo =
    whiteHeader.value || useBlackLogo.value
      ? "ots-logo-mobile-black.svg"
      : "ots-logo-mobile-white.svg";
  return `${getCdnUrl()}/assets/logos/${logo}`;
});

const whiteHeader = computed(() => {
  return windowScrollY.value > 50;
});

onMounted(() => {
  window.addEventListener("scroll", onPageScroll);
  windowScrollY.value = window.scrollY;
});

function onPageScroll(): void {
  windowScrollY.value = window.scrollY;
}

function onOpenContactForm() {
  if (route.name === "index") {
    const signupSection = document.getElementById("signupSection");
    signupSection!.scrollIntoView(false);
  } else {
    openForm.value = true;
  }

  sendScreenView({
    action: "PageView",
    category: "contact-form",
  });
}
</script>

<template>
  <header :class="{ 'white-header': whiteHeader }">
    <div class="header-container">
      <div class="brand">
        <nuxt-link to="/" aria-label="Go to home page">
          <div class="ots-logo">
            <nuxt-img
              :src="getLogo"
              alt="One Times Square"
              width="350"
              height="40"
            />
          </div>

          <div class="ots-logo-mobile">
            <nuxt-img
              :src="getLogoMobile"
              alt="One Times Square"
              width="115"
              height="73"
            />
          </div>
        </nuxt-link>
      </div>

      <template v-if="authenticated">
        <div class="spacer-pipe" />
        <TheMenu />
      </template>

      <div class="find-out-more">
        <button
          v-if="!isGdprRestricted"
          class="primary-button"
          aria-label="Contact Us"
          @click="onOpenContactForm"
        >
          Stay in the loop
        </button>

        <button
          v-if="!isGdprRestricted && authenticated"
          class="primary-button"
          aria-label="Buy Tickets"
          @click="openTicketsModal = true"
        >
          Buy Tickets
        </button>

        <UserProfile v-if="authenticated" />
      </div>
    </div>
  </header>

  <teleport to="body">
    <Transition name="modal">
      <Modal v-if="openForm" @close-modal="openForm = false">
        <template #content>
          <SignUp @close="openForm = false" />
        </template>
      </Modal>
    </Transition>
  </teleport>

  <Teleport to="body">
    <Transition name="modal">
      <Modal
        v-if="openTicketsModal"
        :backdrop-only="true"
        @close-modal="openTicketsModal = false"
      >
        <BuyTickets />
      </Modal>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
@import "../assets/scss/_vars";
header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 0 2rem;
  transition: all 0.5s;

  @media (max-width: $device-xs) {
    padding: 0 1rem;
  }

  &.white-header {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 3px 12px #00000029;
    backdrop-filter: blur(10px);
  }

  .header-container {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    width: 100%;
    max-width: $max-view-width;
    margin: auto;
    padding: 2.5rem 1rem 2.5rem 1rem;

    @media (max-width: $device-xl) {
      padding: 3rem 1rem 2rem 1rem;
    }

    @media (max-width: $device-sm) {
      gap: 2rem;
      align-items: flex-start;
    }

    .ots-logo {
      img {
        width: 350px;
        @media (max-width: $device-sm) {
          width: 100%;
        }
      }

      @media (max-width: $device-sm) {
        display: none;
      }
    }

    .ots-logo-mobile {
      @media (min-width: 821px) {
        display: none;
      }

      img {
        width: 115px;
      }
    }

    .spacer-pipe {
      width: 3px;
      height: 40px;
      background-color: darkgray;
    }

    .find-out-more {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 2rem;
      margin-left: auto;

      @media (max-width: $device-sm) {
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-end;
        justify-items: flex-start;
      }

      p {
        text-align: right;
        font-size: 1.7rem;
        font-weight: 700;
        margin: 0;
        color: white;
        max-width: 400px;
        padding-left: 20px;
      }

      a {
        margin-left: 20px;
        white-space: nowrap;

        @media (max-width: $device-xs) {
          font-size: 1.5rem;
          padding: 0.5rem 2rem !important;
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
