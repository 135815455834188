<script setup lang="ts">
import type { UserProfile } from "@/types";
import { storeToRefs } from "pinia";

const profile = ref<UserProfile>();
const isOpen = ref(false);

const profileMenu = ref<HTMLElement>();
const toggleButton = ref<HTMLElement>();

const { authenticated } = storeToRefs(useAuthStore());
const { getUserProfile } = useAuthStore();

onMounted(async () => {
  profile.value = await getUserProfile();
});

function toggleMenu() {
  isOpen.value = !isOpen.value;

  if (isOpen.value) {
    window.addEventListener("click", detectClickOutside);
  }
}

function detectClickOutside(evt: Event) {
  if (toggleButton.value) {
    if (
      (evt.target as Element) !== toggleButton.value &&
      !toggleButton.value.contains(evt.target as Element)
    ) {
      window.removeEventListener("click", detectClickOutside);
      isOpen.value = false;
    }
  }
}

function onSignout() {
  const { signOut } = useAuthStore();
  signOut();
  navigateTo(`/login`);
}
</script>

<template>
  <div v-if="authenticated" class="user-profile">
    <div ref="toggleButton" class="profile-image" @click="toggleMenu">
      <UiIconUser />
    </div>

    <div v-if="isOpen" ref="profileMenu" class="profile-menu">
      <ul v-if="profile">
        <li>{{ profile.name }}</li>
        <li>{{ profile.email }}</li>
      </ul>
      <ul>
        <li>
          <NuxtLink to="/features-under-development"
            >Features Under Development</NuxtLink
          >
        </li>
        <li><NuxtLink @click="onSignout">Sign out</NuxtLink></li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.user-profile {
  position: relative;
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    background-color: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center;
    }
  }

  .profile-menu {
    margin-top: 1rem;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100px;
    background: lightgray;
    padding: 0 1rem;
    border-radius: 3px;

    ul {
      list-style-type: none;
      padding: 1.25rem 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &:not(:last-child) {
        border-bottom: 1px solid black;
      }

      li {
        font-size: 1.25rem;
        a {
          color: black;
          margin: 0;
          font-weight: 600;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
