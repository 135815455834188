<script setup lang="ts">
const { sendScreenView } = useAnalytics();

function trackView(url: string): void {
  sendScreenView({ action: "UrlClickWeb", category: url }, true);
}

const { getYear } = useUtils();

const { getCdnUrl } = useEnvPaths();
</script>

<template>
  <footer>
    <div class="footer-content">
      <div class="jamestown-logo">
        <div>Owned, managed,<br />and operated by:</div>
        <a
          href="https://www.jamestownlp.com/"
          target="_blank"
          aria-label="Go to Jamestown LP website"
          @click="trackView('JamestownWebsite')"
        >
          <nuxt-img
            :src="`${getCdnUrl()}/assets/logos/jamestown-logo.svg`"
            alt="Jamestown LP"
            width="150"
          />
        </a>
      </div>
      <div class="footer-connect">
        For general inquiries, contact
        <a
          href="mailto:connect@onetimessquare.com"
          aria-label="Email One Times Square"
          @click="trackView('Email.connect@onetimessquare')"
          >connect@onetimessquare.com</a
        >
      </div>
      <div class="privacy">
        <div>&copy;{{ getYear() }} All rights reserved Jamestown L.P.</div>
        <a
          href="https://www.jamestownlp.com/privacy-policy-and-disclaimers"
          target="_blank"
          aria-label="Read privacy policy"
          @click="trackView('JamestownPrivacyPolicy')"
          >Privacy Policy</a
        >
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@import "@/assets/scss/_vars";
footer {
  background-color: black;
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: $device-xl;
    color: white;
    margin: auto;
    padding: 2rem;
    align-items: center;

    @media (max-width: $device-md) {
      flex-direction: column;
      font-size: 2rem;
      gap: 2rem;
      div {
        display: flex;
        width: 100%;
        text-align: center;

        flex-direction: column !important;
      }

      .footer-connect {
        font-size: 2rem !important;
      }
    }

    .jamestown-logo {
      display: inline-flex;
      gap: 1rem;
      align-items: center;

      img {
        width: 150px;
      }
    }

    .footer-connect {
      font-size: 1.6rem;
    }

    .privacy {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      a {
        text-decoration: underline !important;
      }

      a {
        text-decoration: none;
        &:visited {
          color: white;
        }
      }
    }
  }
}
</style>
