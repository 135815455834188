<template>
  <nav class="the-menu">
    <ul>
      <li>
        <NuxtLink>Times Travel</NuxtLink>
      </li>
      <li>
        <NuxtLink>NextGen</NuxtLink>
      </li>
      <li>
        <NuxtLink to="/weddings">Wedding</NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
.the-menu {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 4rem;

    li {
      a {
        color: black;
        text-decoration: none;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }
}
</style>
