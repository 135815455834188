import { googleTokenLogin, googleLogout } from "vue3-google-login";
import type { SocialLoginResponse } from "@/types/";

export const useGoogleLogin = () => {
	const googleLogin = async (): Promise<SocialLoginResponse> => {
		return new Promise(async (resolve) => {
			const { data: clientId } = await useFetch("/api/auth/google-client-id");

			googleTokenLogin({ clientId: clientId.value }).then((resp) => {
				const data: SocialLoginResponse = {
					type: "google",
					access_token: resp.access_token,
				};
				resolve(data);
			});
		})
	}

	// google.accounts.oauth2.revoke(accessToken: string, done: () => void): void;

	return {
		googleLogin,
		googleLogout,
	}
}