import { defineStore } from 'pinia';
import { locales } from '../static/locales';

type GeoLocation = {
	city: string;
	region: string;
	country: string;
	timezone: string;
}

const hasConsented = ref(false);

export const useGdprStore = defineStore('gdprStore', () => {
	const isGdprRestricted = ref(true);
	const geoLocation = ref<GeoLocation>();

	const checkGdprLocale = async (): Promise<boolean> => {
		if (!geoLocation.value) {
			try {
				geoLocation.value = await $fetch('/auth/geo');
				if (geoLocation.value) {
					isGdprRestricted.value = !!locales.includes(geoLocation.value.country);
				}
			} catch(e) {
				console.log('Cannot get user locale.');
				isGdprRestricted.value = false;
			}
		}

		return isGdprRestricted.value;
	}

	return {
		hasConsented,
		isGdprRestricted,
		geoLocation,
		checkGdprLocale,
	}
});