import revive_payload_client_4sVQNw7RlN from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/webapp-ots-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_m9SjywKgWm from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_t2GMTTFnMT from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/vsts/work/1/s/webapp-ots-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_datepicker_plugin_3Kygh7M1Wf from "/home/vsts/work/1/s/webapp-ots-nuxt/plugins/vue-datepicker.plugin.ts";
import vue_gtag_plugin_OPIcdkRTEm from "/home/vsts/work/1/s/webapp-ots-nuxt/plugins/vue-gtag.plugin.ts";
import gsap_plugin_qXQGvSxOPj from "/home/vsts/work/1/s/webapp-ots-nuxt/plugins/gsap.plugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  vue_datepicker_plugin_3Kygh7M1Wf,
  vue_gtag_plugin_OPIcdkRTEm,
  gsap_plugin_qXQGvSxOPj
]